import { female } from './Funnels/female';
import { male } from './Funnels/male';
import { sugar } from './Funnels/sugar';
import { IProduct } from '../../types';
import { findProductBySku } from '../../helpers/products';

export const Funnels = {
  female,
  male,
  sugar,
  default: female,
};

export const FunnelRoutes = {
  HOME: '/',
  LANDING_GOAL: '/landing-goal',
  CORTISOL: '/cortisol',
  ANTI_INFLAMMATORY: '/anti-inflammatory',
  CHAT: '/chat',
  QUIZ: '/quiz',
  CHECKING: '/checking',
  GET_STARTED: '/get-started',
  SUMMARY: '/summary',
  PROFILE_DATA: '/profile-data',
  PLAN_READY: '/plan-ready',
  PURCHASE_PLAN: '/purchase-plan',
  PURCHASE_PLAN1: '/purchase-plan-1',
  PURCHASE_PLAN2: '/purchase-plan-2',
  CHECKOUT: '/checkout',
  UPSELL: {
    Strategy1: {
      UPSELL_1: '/faster-upsell',
      UPSELL_2: '/recipes-upsell',
      UPSELL_3: '/premium-upsell',
      ORDER: ['/faster-upsell', '/recipes-upsell', '/premium-upsell'],
    },
    Strategy2: {
      UPSELL_1: '/goals-upsell',
      UPSELL_2: '/special-offer-upsell',
      UPSELL_3: '/recipes-upsell',
      UPSELL_4: '/flavor-upsell',
      UPSELL_5: '/dessert-upsell',
      UPSELL_6: '/faster-upsell',
      ORDER: [
        '/goals-upsell',
        '/special-offer-upsell',
        '/recipes-upsell',
        '/flavor-upsell',
        '/dessert-upsell',
        '/faster-upsell',
      ],
    },
  },
  REGISTER: '/register',
  REGISTER_APP: '/register-app',
  THANK_YOU: '/thank-you',
};

export const UpsellsOrder = {
  default: FunnelRoutes.UPSELL.Strategy2.ORDER,
};
export const RoutesOrder = {
  default: [
    FunnelRoutes.HOME,
    FunnelRoutes.QUIZ,
    FunnelRoutes.SUMMARY,
    FunnelRoutes.PROFILE_DATA,
    FunnelRoutes.CHECKING,
    FunnelRoutes.GET_STARTED,
    // FunnelRoutes.PLAN_READY,
    FunnelRoutes.PURCHASE_PLAN,
    FunnelRoutes.CHECKOUT,
    FunnelRoutes.REGISTER,
    FunnelRoutes.THANK_YOU,
  ],
};

export const ProductSkus = {
  sixMonthPlan: 'sixMonthPlan',
  threeMonthPlan: 'threeMonthPlan',
  oneMonthPlan: 'oneMonthPlan',
  sevenDayPlan: 'sevenDayPlan',
  premiumUpsell: 'premiumUpsell',
  fasterUpsell: 'fasterUpsell',
  recipesUpsell: 'recipesUpsell',
  goalsUpsell: 'goalsUpsell',
  luckyUpsell: 'luckyUpsell',
  specialOfferUpsell: 'specialOfferUpsell',
  flavorUpsell: 'flavorUpsell',
  dessertUpsell: 'dessertUpsell',
};

export const getProducts = (products: IProduct[]) => ({
  sixMonthPlan: findProductBySku(products, ProductSkus.sixMonthPlan),
  threeMonthPlan: findProductBySku(products, ProductSkus.threeMonthPlan),
  oneMonthPlan: findProductBySku(products, ProductSkus.oneMonthPlan),
  sevenDayPlan: findProductBySku(products, ProductSkus.sevenDayPlan),
  premiumUpsell: findProductBySku(products, ProductSkus.premiumUpsell),
  fasterUpsell: findProductBySku(products, ProductSkus.fasterUpsell),
  recipesUpsell: findProductBySku(products, ProductSkus.recipesUpsell),
  goalsUpsell: findProductBySku(products, ProductSkus.goalsUpsell),
  luckyUpsell: findProductBySku(products, ProductSkus.luckyUpsell),
  specialOfferUpsell: findProductBySku(products, ProductSkus.specialOfferUpsell),
  flavorUpsell: findProductBySku(products, ProductSkus.flavorUpsell),
  dessertUpsell: findProductBySku(products, ProductSkus.dessertUpsell),
});

export const ProjectName = 'Inflammi Plan';
